import axios from 'axios' // 引入axios
import {Toast} from "vant";
import { store } from '@/store'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 99999
})

// http request 拦截器
service.interceptors.request.use(
    config => {
        const token = store.getters['user/token']
        config.data = JSON.stringify(config.data)
        config.headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            ...config.headers
        }
        return config
    },
    error => {
        Toast.fail(error)
        return error
    }
)

// http response 拦截器
service.interceptors.response.use(
    response => {

        // if (response.headers['new-token']) {
        //     store.commit('user/setToken', response.headers['new-token'])
        // }
        if (response.data.code === 0 || response.headers.success === 'true') {
            return response.data
        } else {
            Toast.fail(response.data.message || decodeURI(response.headers.message))
            return response.data.message ? response.data : response
        }
    },
    error => {
        Toast.fail("服务器错误，请联系管理员")
        return error
    }
)

export default service
